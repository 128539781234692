import router from '@/router/router';
import * as Sentry from '@sentry/vue';
import { getCompanies } from '@/store/modules/companies/companies-actions';
import { logout } from '@/store/modules/auth/auth-actions';
import { globalError } from '@/store/modules/global/global-state';

export const setErrorMessage = async (error: string) => {
  globalError.value = error;
  return error;
};

export const logoutUserOnError = async (error: string) => {
  await logout();
  return await setErrorMessage(error);
};

export const returnUserAssignments = async (error: string) => {
  const { params, name } = router.currentRoute;
  const { companyId } = params;
  if (router.currentRoute.name === 'companies') {
    return;
  }
  if (!companyId) {
    return router.push(`/companies`);
  }

  if (name !== 'assignments') await router.push(`/company/${companyId}/assignments`);

  return await setErrorMessage(error);
};

export const returnUserCompanies = async (error: string) => {
  const { name } = router.currentRoute;
  if (name !== 'companies') await router.push('/companies');
  if (name === 'companies') await getCompanies();
  return await setErrorMessage(error);
};

export const handleApiError = async (statusCode: number, error: string) => {
  if (statusCode === 401) {
    return await logoutUserOnError(error);
  }
  if (statusCode >= 552 && statusCode <= 554) {
    return await returnUserCompanies(error);
  }

  if (statusCode >= 550 || statusCode <= 551 || statusCode >= 555 || statusCode <= 557) {
    return await returnUserAssignments(error);
  }
  return await setErrorMessage(error);
};

export function handleErrorSentry(payload: { statusCode: number; data: any; url: string }) {
  const error = new Error(JSON.stringify(payload));
  Sentry.captureException(error);
}
