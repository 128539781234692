import { Route } from 'vue-router';
import { effectScope, reactive } from 'vue';
import router from '@/router/router';

/**
 * this.$router is not reactive, so can not use $router in computed.
 * Relying on this.$route instead
 */
export function isInitialized(route: Route) {
  return route.matched.length > 0 || route.fullPath !== '/';
}

let currentRoute: Route;

/** Implementation picked from vue2-helpers */
export function useRoute(): Route {
  if (!router) {
    return undefined as any;
  }
  if (!currentRoute) {
    const scope = effectScope(true);
    scope.run(() => {
      currentRoute = reactive(assign({}, router.currentRoute)) as any;
      router.afterEach(to => {
        assign(currentRoute, to);
      });
    });
  }
  return currentRoute;
}

function assign(target: Record<string, any>, source: Record<string, any>) {
  for (const key of Object.keys(source)) {
    target[key] = source[key];
  }
  return target;
}

export function onError(err: any) {
  if (err.name !== 'NavigationDuplicated') {
    throw err;
  }
}
