<template>
  <div
    class="dnl-flex dnl-px-4 dnl-py-3 dnl-items-center dnl-justify-between dnl-sticky dnl-top-0 dnl-z-50 dnl-bg-white dnl-shadow-md dnl-shadow-gray-400"
  >
    <router-link
      title="Home"
      :to="user ? '/' : '/login'"
      data-testId="home"
      class="dnl-flex dnl-items-center"
    >
      <img
        v-if="user && user.userGroup"
        :src="logoUrl"
        :alt="user.userGroup"
        class="dnl-h-8"
      />
      <img
        v-else
        src="@/assets/icon-br-m.png"
        alt="DNL logo"
        class="dnl-h-8"
      />
    </router-link>

    <BreadCrumbs
      v-if="breadcrumbs.length > 0"
      :breadcrumbs="breadcrumbs"
      :hasHomeIcon="false"
    />

    <AppBarMenu :showFullMenu="!!user" />
  </div>
</template>

<script lang="ts" setup>
  import { computed, onBeforeUnmount, watch } from 'vue';
  import { useRoute } from '@/utils/router';
  import BreadCrumbs from '@/components/Shared/BreadCrumbs.vue';
  import defaultLogo from '@/assets/icon-br-m.png';
  import { breadcrumbsStore, setBreadcrumbs } from '@/services/breadcrumbs.service';
  import { user } from '@/store/modules/auth/auth-state';
  import AppBarMenu from './AppBarMenu.vue';

  const route = useRoute();

  const breadcrumbs = computed(() => breadcrumbsStore.breadcrumbs);
  const logoUrl = computed(() => user.value?.userGroupLogoURL || defaultLogo);

  watch(
    () => route.path,
    () => {
      setBreadcrumbs([]);
    },
  );

  /** fix for the bug when breadcrumbs are visible after relogin in the wrong place
   * TODO: reset the whole app state on logout
   */
  onBeforeUnmount(() => {
    setBreadcrumbs([]);
  });
</script>
