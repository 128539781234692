export const de_DE = {
  mediumTime: {
    hour: '2-digit',
    minute: '2-digit',
  },
  baseDate: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  fullDate: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

export const en_GB = {
  mediumTime: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  baseDate: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  fullDate: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};
