import { env } from '@/constants/env';
import { createFetch } from '@vueuse/core';
import { handleApiError, handleErrorSentry } from '@/services/apiErrors';
import { token } from '@/store/modules/auth/auth-state';
import { CustomFetchHeaders } from '@/types/common';

const useFetchNotesAuditor = createFetch({
  baseUrl: env.VITE_APP_URL_API,
  options: {
    async beforeFetch({ options }) {
      if (options?.headers) {
        const headers = options.headers as CustomFetchHeaders;
        headers.Authorization = `Bearer ${token.value}`;
        headers.timestamp = (new Date().getTime() / 1000).toString();
      }

      return { options };
    },
    async onFetchError({ data, response }) {
      let exportErrorCode = '';

      if (data && data instanceof Blob) {
        exportErrorCode = `error.${JSON.parse(await data.text())?.errorData?.errorCode}`;
      }

      const statusCode = response?.status as number;
      const errorCode = data?.errorData?.errorCode;
      const errorCodeTranslationKey = errorCode ? `error.${errorCode}` : null;
      const textToShow = errorCodeTranslationKey || data?.detail || data?.msg;

      // We do not want to show a global error message if the error is an export file error
      if (exportErrorCode !== 'exportNotPossibleNumbersNotChecked') {
        await handleApiError(statusCode, textToShow);
        handleErrorSentry({ statusCode, data, url: response?.url as string });
      }

      return { error: exportErrorCode, data, response };
    },
  },
  fetchOptions: {
    mode: 'cors',
  },
});

export default useFetchNotesAuditor;
