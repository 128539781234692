import Vue from 'vue';
import Router, { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import routes from './routes';
import { token } from '@/store/modules/auth/auth-state';
import { refreshUserCredentials } from '@/store/modules/auth/auth-actions';
import { globalError } from '@/store/modules/global/global-state';

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (to.name === 'loginOpenIDConnect') {
    await handleLoginOpenIDConnectRoute(next);
  } else if (to.name === 'login' && token.value) {
    const isLogout = to.query.logout;
    if (!isLogout) return next({ name: 'companies' });
  }

  if (to.matched.some((record: RouteRecord): boolean => record.meta.requiresAuth)) {
    // If token is not present, redirect to login
    if (!token.value) {
      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }

    // Refesh user credentials on the first load
    if (!from.name) {
      try {
        const { success } = await refreshUserCredentials({ initLogoutTimer: true });
        if (!success) {
          return next({
            path: '/login',
            query: { redirect: to.fullPath },
          });
        }
      } catch (_) {
        return next({
          path: '/login',
          query: { redirect: to.fullPath },
        });
      }
    }
  }

  next();
});

async function handleLoginOpenIDConnectRoute(next: NavigationGuardNext<Vue>) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const redirectURL = params.redirect;
  const isVerified = params.is_verified === 'true';
  const isSSOLoginError = params.error === 'sso_failed';
  const { token: ssoToken } = params;
  if (isSSOLoginError || !isVerified) {
    globalError.value = {
      text: !isVerified ? 'login.error.unverified' : 'login.error.sso',
    };
    return next({ path: '/login' });
  } else if (ssoToken) {
    token.value = ssoToken;
    await refreshUserCredentials({
      forceRefresh: true,
      initLogoutTimer: true,
    });

    return next({ path: redirectURL || '/companies' });
  }
}

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    console.log('chunk error, reloading');
  }
});

Vue.use(Router);

export default router;
