import { reactive, toRefs } from 'vue';
import { CompaniesState } from './companies-types';

const state = reactive<CompaniesState>({
  companies: [],
  companyTableHeaders: [],
  companyForm: null,
  companiesErrorMessage: null,
  companiesErrorData: null,
});

export const {
  companies,
  companyForm,
  companyTableHeaders,
  companiesErrorMessage,
  companiesErrorData,
} = toRefs(state);

export default state;
