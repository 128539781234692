import { reactive, toRefs } from 'vue';
import type { CoreState } from './global-types';
import {
  getSavedDataFromLocalStorage,
  useStateLocalstorageSync,
} from '@/utils/localStorage/localStorage';
import { InteractionModes } from '@/interfaces/annotations';

const savedData = getSavedDataFromLocalStorage(['defaultInteractionMode']);

const coreState = reactive<CoreState>({
  globalError: null,
  dialog: null,
  defaultInteractionMode: savedData.defaultInteractionMode ?? InteractionModes.SELECT_ANNOTATION,
});

export const { globalError, defaultInteractionMode } = toRefs(coreState);

useStateLocalstorageSync('defaultInteractionMode', defaultInteractionMode);

export default coreState;
