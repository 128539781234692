import authStore from '@/store/modules/auth/auth-state';

/**
 * Applying additional usersnap configuration
 */
export const initUsersnap = async () => {
  const api = await getUsersnap();

  api.on('beforeSubmit', (event: any) => {
    const user = authStore.user;

    if (user) {
      event.api.setValue('custom', {
        userId: user.id,
        groupId: user.groupId,
        groupName: user.userGroup,
        email: user.email,
      });
    }
  });
};

/**
 * Wait for usersnap script initialization or
 * return it immediately if it's already initialized
 */
export function getUsersnap(): Promise<any> {
  return new Promise(resolve => {
    if (window.Usersnap) {
      resolve(window.Usersnap);
    } else {
      window.addEventListener('onUsersnapInit', (e: CustomEventInit) => {
        resolve(e.detail);
      });
    }
  });
}
