import { reactive } from 'vue';

export interface ToastProps {
  color?: string;
  bgColor?: string;
  text: string;
  icon?: string;
  iconSize?: number | string;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  duration?: number;
  testId?: string;
  contentClass?: string;
  visible: boolean;
}

export const defaultToastProps: Partial<ToastProps> = {
  color: 'black',
  bgColor: 'slate-100',
  text: '',
  icon: '',
  iconSize: 5,
  contentClass: '',
  position: 'bottom-right',
  testId: 'toast',
  duration: 3000,
};

export const toastProps = reactive<ToastProps>({
  color: defaultToastProps.color,
  bgColor: defaultToastProps.bgColor,
  text: '',
  icon: defaultToastProps.icon,
  contentClass: defaultToastProps.contentClass,
  iconSize: defaultToastProps.iconSize,
  position: defaultToastProps.position,
  duration: defaultToastProps.duration,
  testId: defaultToastProps.testId,
  visible: false,
});

const show = (props: Partial<ToastProps>) => {
  toastProps.color = props.color || defaultToastProps.color;
  toastProps.bgColor = props.bgColor || defaultToastProps.bgColor;
  toastProps.text = props.text || '';
  toastProps.icon = props.icon || defaultToastProps.icon;
  toastProps.iconSize = props.iconSize || defaultToastProps.iconSize;
  toastProps.position = props.position || defaultToastProps.position;
  toastProps.duration = props.duration || defaultToastProps.duration;
  toastProps.contentClass = props.contentClass || defaultToastProps.contentClass;

  toastProps.testId = props.testId || defaultToastProps.testId;
  toastProps.visible = true;
};

export const toast = {
  show,
};
