<template>
  <sl-alert
    v-if="error"
    ref="alert"
    :open="!toast"
    :variant="variant"
    :closable="closable"
    :duration="toast ? duration : undefined"
    @sl-hide="dismiss"
  >
    <dnl-icon
      v-if="withIcon && icons[variant]"
      data-testId="icon"
      slot="icon"
      :icon="icons[variant]"
      class="dnl-w-5 dnl-h-5"
    />
    <div
      class="dnl-flex dnl-items-center"
      data-testId="alert-content"
    >
      <span v-if="!error.key">{{ $tn(error.text || error) }}</span>
      <span v-if="error.key">{{ $tn(error.key, error.values) }}</span>
    </div>
  </sl-alert>
</template>

<script setup lang="ts">
  import { computed, ref, watchEffect } from 'vue';
  import type { ErrorAlert } from '@/interfaces/common';

  export interface Props {
    error?: ErrorAlert | null;
    closable?: boolean;
    toast?: boolean;
    duration?: number;
    withIcon?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    error: null,
    toast: false,
    duration: 8000,
    withIcon: true,
  });

  const alert = ref<any>(null);

  const icons = {
    danger: 'dnl-alert',
    warning: 'dnl-alert',
    success: 'mdi-check-circle',
  };

  watchEffect(() => {
    if (!props.toast) return;

    if (props.error) {
      alert.value?.toast();
    } else {
      alert.value?.hide();
    }
  });

  const variant = computed(() => {
    if (typeof props.error === 'string') {
      return 'danger';
    }
    return props.error?.color ?? 'danger';
  });

  const emits = defineEmits<{
    (e: 'dismiss'): void;
  }>();

  function dismiss() {
    emits('dismiss');
  }
</script>
