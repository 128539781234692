import coreState from './global-state';
import { DialogType } from './global-types';

/** Creates a global dialog */
export function dialog(dialog: DialogType) {
  coreState.dialog = dialog;
}

export function clearDialog() {
  coreState.dialog = null;
}
