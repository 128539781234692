import { useLanguageStore } from '@/services/language.service';
import { VueConstructor } from 'vue';
import VueI18n, { TranslateResult } from 'vue-i18n';
import { createI18n } from 'vue-i18n-bridge';
import { de_DE, en_GB } from '@/constants/dateTimeFormats';
import { Locale } from '@/constants/languages';

async function loadLocaleMessages() {
  const contexts = import.meta.glob('../translations/*.json'),
    messages = {};
  for (const path in contexts) {
    const regex = /([a-z]{2}-[A-Z]{2})/g;
    const match = path.match(regex);
    const [locale] = match;
    messages[locale] = await contexts[path]().then(m => m.default);
  }

  return Promise.resolve(messages);
}

export async function setupI18n(vue: VueConstructor, locale?: Locale) {
  vue.use(VueI18n, { bridge: true });

  const messages = await loadLocaleMessages();

  const i18n = createI18n(
    {
      legacy: false,
      locale: locale ?? useLanguageStore.language,
      messages,
      datetimeFormats: {
        'de-DE': de_DE,
        'en-GB': en_GB,
      },
    },
    VueI18n,
  );

  vue.use(i18n);
  enableCustomI18nDirectives(vue, messages);

  return i18n;
}

/** If there is a translation - translate it, if no - return the label (without warning) */
function conditionalTranslation(vue: VueConstructor, messages: any) {
  return function (key: string, ...values: any): TranslateResult {
    return messages['de-DE'][key] ? vue.prototype.$t(key, ...values) : key;
  };
}

export function enableCustomI18nDirectives(vue: VueConstructor, messages: any) {
  vue.prototype.$tn = conditionalTranslation(vue, messages);
}
