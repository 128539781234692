<template>
  <v-app>
    <component :is="layout">
      <router-view></router-view>
    </component>
    <GlobalDialog />
    <Errors />
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Errors from '@/components/Containers/Errors.vue';
  import GlobalDialog from '@/components/Containers/GlobalDialog.vue';
  import DefaultLayout from '@/components/Layouts/DefaultLayout.vue';
  import EmptyLayout from '@/components/Layouts/EmptyLayout.vue';
  import { isInitialized } from '@/utils/router';
  import { initUsersnap } from '@/utils/usersnap';

  // Global styles shared by multiple components
  import '@/styles/app.scss';
  import { token as stateToken } from './store/modules/auth/auth-state';
  import { socket, socketConnectWithAuth, socketUpdateToken } from './socket';

  initUsersnap();

  export default Vue.extend({
    name: 'App',
    components: {
      Errors,
      default: DefaultLayout,
      empty: EmptyLayout,
      GlobalDialog,
    },
    computed: {
      layout() {
        if (!isInitialized(this.$route)) {
          return null;
        }

        const layoutMeta = this.$route.meta?.layout;

        if (layoutMeta === null) return 'empty';
        return layoutMeta ?? 'default';
      },
      token() {
        return stateToken.value;
      },
    },
    watch: {
      token: {
        handler(newToken) {
          if (!newToken) {
            return;
          }

          if (!socket) {
            socketConnectWithAuth(newToken);
          } else {
            socketUpdateToken(newToken);
          }
        },
        immediate: true,
      },
    },
  });
</script>
