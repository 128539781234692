/* eslint-disable @typescript-eslint/no-restricted-types */

import { ConnectedReferenceStateType } from '@/store/modules/checklistFlow/checklistFlow-types';

export interface AnnotationText {
  backgroundColor?:
    | Iterable<[string, string]>
    | Partial<{ r: number; g: number; b: number }>
    | undefined;
  fontColor?: Iterable<[string, string]> | Partial<{ r: number; g: number; b: number }> | undefined;
  set?: Function;
  isDeletable?: boolean;
  boundingBox: IRect;
  blendMode: IBlendMode;
  color: Record<string, number>;
  createdAt?: string;
  id: string;
  name: string;
  opacity: number;
  pageIndex: number;
  rects: IRect[];
  type?: string;
  updatedAt?: string;
  icon: string;
  v?: string;
  toJS?: Function;
  text:
    | {
        value: string;
        format: 'plain' | 'xhtml';
      }
    | string;
  trigger?: string;
  customData: {
    isHighlighted?: boolean;
    text?: string;
    type?: string;
    id?: string;
    connectedQuestions: ConnectedQuestion[];
    connectedCommentIds?: string[];
    connectedProposedChangeId?: string;
    documentId: string;
    documentName?: string;
  };
}

export interface ConnectedQuestion {
  questionId?: string;
  state: ConnectedReferenceStateType;
}

interface Rectangle {
  left: number;
  top: number;
  height: number;
  width: number;
}

interface Comment {
  id: string;
  documentId: string;
  referenceId: string;
  text: string;
  isImportedFromWord: boolean;
  createdAt: string;
  author: string;
}

interface CustomData {
  text: string;
  type: string;
  documentId: string;
  connectedCommentIds: string[];
  connectedProposedChangeId: string | null;
  comments: Comment[];
}

interface Color {
  b: number;
  g: number;
  r: number;
}

export interface Annotation {
  id: string;
  name: string;
  pageIndex: number;
  boundingBox: Rectangle;
  customData: CustomData;
  text: string | null;
  rects: Rectangle[];
  hidden: boolean;
  noView: boolean;
  noZoom: boolean;
  noPrint: boolean;
  opacity: number;
  noRotate: boolean;
  isCommentThreadRoot: boolean;
  color: Color;
  icon: string | null;
  isDeletable: boolean | null;
}

export enum InteractionModes {
  MULTISELECT_ANNOTATIONS,
  SELECT_ANNOTATION,
  SINGLE_RECT,
  MULTI_RECT,
}
