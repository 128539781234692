export type Locale = 'de-DE' | 'en-GB'

export const SUPPORTED_LANGUAGES: { name: string, tag: Locale }[] = [
  {
    name: 'Deutsch',
    tag: 'de-DE',
  },
  {
    name: 'English',
    tag: 'en-GB',
  },
];

export const APP_DEFAULT_LANGUAGE: Locale = 'de-DE';

export const LANGUAGE_LOCAL_STORAGE_KEY = 'NA_TRANSLATE_LANG_KEY';
