import colors, { RGBAToHexA } from '@/constants/colors';
import Vue from 'vue';
import Vuetify, {
  VApp,
  VTooltip,
  VBtn,
  VIcon,
  VFadeTransition,
  VCardTitle,
  VCardActions,
  VTextField,
  VMenu,
  VCard,
  VDialog,
  VProgressCircular,
  VCardText,
} from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify, {
  components: {
    VApp,
    VTooltip,
    VBtn,
    VIcon,
    VFadeTransition,
    VCardTitle,
    VCardActions,
    VTextField,
    VMenu,
    VCard,
    VDialog,
    VProgressCircular,
    VCardText,
  },
});

export default new Vuetify({
  lang: {
    locales: { 'de-DE': de, 'en-GB': en },
    current: 'de-DE',
  },
  theme: {
    themes: {
      light: {
        // Vuetify only accepts hex colors
        primary: RGBAToHexA(colors['blue-600']),
        success: RGBAToHexA(colors['green-600']),
        error: RGBAToHexA(colors['red-500']),
      },
    },
  },
});
