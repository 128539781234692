import type { User } from '@/store/modules/auth/auth-types';
import useFetchNotesAuditor from '@/api/notesAuditorInstance';
import {
  APP_DEFAULT_LANGUAGE,
  LANGUAGE_LOCAL_STORAGE_KEY,
  Locale,
  SUPPORTED_LANGUAGES,
} from '@/constants/languages';
import { Language } from '@/interfaces/Languages';
import { reactive } from 'vue';
import { getUserLanguage } from './auth';

export const useLanguageStore = reactive({
  language: getDefaultLanguage(),
});

/**
 * Adds a property to each language containing the country short code
 * @param {Array} language
 * @returns {Array}
 */
function addShortCodeKey(language: Language) {
  return {
    ...language,
    shortCode: language.tag && language.tag.split('-')[0].toUpperCase(),
  };
}

/**
 * Returns an array of language objects consisting of tag and name and shortCode properties.
 * @returns {Language[]}
 */
export function getLanguages() {
  return SUPPORTED_LANGUAGES.map(addShortCodeKey);
}

/**
 * This function checks in the supported languages to see if the language is supported
 * Retruns a boolean based on existence of the language
 *
 * @returns {boolean}
 */
function isLanguageSupported(languageTag: string): boolean {
  return Boolean(getLanguages().find((lang: Language) => lang.tag === languageTag));
}

/**
 * This function is used to get the language from local storage
 *
 * @returns {Locale} - language tag
 */
function getDefaultLanguage(): Locale {
  const urlParams = new URLSearchParams(window.location.search);
  const userLanguage = getUserLanguage();

  const defaultLanguage = (urlParams.get('lang') ||
    localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) ||
    userLanguage ||
    navigator.language) as Locale;

  return isLanguageSupported(defaultLanguage) ? defaultLanguage : APP_DEFAULT_LANGUAGE;
}

/**
 * This function is used to set the language in the store and to the localStorage
 * It only saves the language tag
 *
 * @returns {void}
 */
export function setLanguage(languageTag: Locale) {
  useLanguageStore.language = languageTag;
  setLanguageToLocalStorage(languageTag);
  setTimeout(() => {
    const languageChangeEvent = new Event('languageChanged');
    window.dispatchEvent(languageChangeEvent);
  });
}

/**
 * This function is used to set the language in the user settings
 * It only saves the language tag
 *
 * @returns {User}
 */
export async function setLanguageInUserSettings(languageTag: string) {
  try {
    const response = await useFetchNotesAuditor('/users/locale')
      .put({
        locale: languageTag,
      })
      .json();

    if (response.error) return response.error;

    return response.data;
  } catch (err) {
    console.error(err);
  }
}

/**
 * This function is used to set the language in the local storage
 * It only saves the language tag
 *
 * @returns {void}
 */
export function setLanguageToLocalStorage(languageTag: string) {
  localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, languageTag);
}
