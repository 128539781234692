<template>
  <ConfirmDialog
    v-if="dialog"
    isVisible
    :data-testId="dialog.id"
    :dialogButtons="buttons"
    :dialogTitle="dialog?.text"
    :isLoading="loading"
    @discard="discard"
    @confirm="confirm"
    @close="close"
  />
</template>

<script setup lang="ts">
  import ConfirmDialog from '@/components/Shared/Dialogs/ConfirmDialog.vue';
  import coreState from '@/store/modules/global/global-state';
  import { clearDialog } from '@/store/modules/global/global-actions';
  import { computed, ref, watch } from 'vue';
  import { DialogResult } from '@/store/modules/global/global-types';

  const loading = ref(false);

  const dialog = computed(() => coreState.dialog);

  const buttons = computed(() => [
    {
      text: dialog.value?.discard?.text || 'generic.cancel',
      action: 'discard',
      className: dialog.value?.discard?.class || 'dnl-bg-jeans-indigo dnl-text-white',
    },
    {
      text: dialog.value?.confirm?.text || 'generic.confirm',
      action: 'confirm',
      className: dialog.value?.confirm?.class || 'dnl-bg-blue-700 dnl-text-white',
      showLoading: true,
    },
  ]);

  async function discard() {
    await dialog.value?.onResolve(DialogResult.Reject);
    clearDialog();
  }

  async function confirm() {
    try {
      if (dialog.value?.loaderOnResolve) {
        loading.value = true;
      }
      await dialog.value?.onResolve(DialogResult.Accept);
      clearDialog();
    } catch {
      loading.value = false;
    }
  }

  async function close() {
    await dialog.value?.onResolve(DialogResult.Close);
    clearDialog();
  }

  watch(
    () => dialog.value,
    () => {
      loading.value = false;
    },
  );
</script>
