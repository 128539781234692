import { InteractionModes } from '@/interfaces/annotations';
import type { ErrorAlert } from '@/interfaces/common';

export enum DialogResult {
  Accept = 1,
  Reject = 2,
  Close = 3,
}

export type DialogType = {
  text: string;
  /** id - used for passing test-id (e2e) */
  id?: string;
  /** loaderOnResolve - if set to true, loader is shown while onResolve is in running */
  loaderOnResolve?: boolean;
  confirm?: {
    text?: string;
    class?: string;
  };
  discard?: {
    text?: string;
    class?: string;
  };
  onResolve: (result: DialogResult) => Promise<void>;
};

export interface CoreState {
  globalError: ErrorAlert | null;
  dialog: DialogType | null;
  defaultInteractionMode: InteractionModes | null;
}
