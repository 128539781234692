import Vue from 'vue';
import { watchEffect } from 'vue';
import router from './router/router';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import { env } from '@/constants/env';
import 'virtual:uno.css';
import { useLanguageStore } from '@/services/language.service';
import Toast from '@/components/Shared/Toast.vue';
import Icon from '@/components/Shared/DnlIcon.vue';
import '../public/tokens.css';
import './utils/polyfills';
import './utils/shoelace';

// these imports are only for development purposes. Allows dev tools to know about uno classes
import 'uno.css';
import 'virtual:unocss-devtools';
import { setupI18n } from './utils/i18n';
import { initSentry } from './utils/sentry';

const { VITE_APP_ENV } = env;

initSentry(VITE_APP_ENV);

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.component('Toast', Toast);
Vue.component('dnl-icon', Icon);

const i18n = await setupI18n(Vue);

const vueinstance = new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');

watchEffect(() => {
  i18n.locale = useLanguageStore.language;
  vuetify.framework.lang.current = useLanguageStore.language;
  // This line can be removed when we watchEffect in all components
  vueinstance.$root.$emit('language:change', useLanguageStore.language);
});

watchEffect(
  () => {
    if (VITE_APP_ENV !== 'development' && window.StonlyWidget) {
      window.StonlyWidget('setWidgetLanguage', useLanguageStore.language.split('-')[0] || 'de');
    }
  },
  {
    flush: 'post',
  },
);
