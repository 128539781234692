
  if (import.meta.env.VITE_APP_ENV !== 'development' && localStorage.disable_stonly === undefined) {
    !(function(s, t, o, n, l, y, w, g) {
      s.StonlyWidget ||
      (((w = s.StonlyWidget =
        function() {
          w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
        }).scriptPath = n),
        (w.queue = []),
        ((y = t.createElement(o)).async = !0),
        (g = new XMLHttpRequest()).open('GET', n + 'version?v=' + Date.now(), !0),
        (g.onreadystatechange = function() {
          4 === g.readyState &&
          ((y.src =
            n + 'stonly-widget.js?v=' + (200 === g.status ? g.responseText : Date.now())),
            (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
        }),
        g.send());
    })(window, document, 'script', 'https://stonly.com/js/widget/v2/');

    (function(s, t, o, n, l, y, _) {
      if (s.stonlyTrack) return;
      _ = s.stonlyTrack = function() {
        _._api ? _._api.apply(_, arguments) : _.queue.push(arguments);
      };
      _.queue = [];
      y = t.createElement(o);
      y.async = !0;
      y.src = n;
      l = t.getElementsByTagName(o)[0];
      l.parentNode.insertBefore(y, l);
    })(window, document, 'script', 'https://stonly.com/js/tracker/stn.js');
    stonlyTrack('init', window.STONLY_WID);

    // https://stonly.com/kb/guide/en/widget-api-documentation-dZYsbNCUZS/Steps/101903,102160,2058882
    window.addEventListener('languageChanged', () => {
      setStonlyLanguage();
    });

    function setStonlyLanguage() {
      StonlyWidget(
        'setWidgetLanguage',
        localStorage.NA_TRANSLATE_LANG_KEY?.split('-')[0] || 'de',
      );
    }

    setStonlyLanguage();
  }
