import { Breadcrumbs } from '@/interfaces/BreadCrumbs';
import { reactive } from 'vue';

export const breadcrumbsStore = reactive({
  breadcrumbs: [] as Breadcrumbs[],
});

export function setBreadcrumbs(breadcrumbs: Breadcrumbs[]) {
  breadcrumbsStore.breadcrumbs = breadcrumbs;
}
