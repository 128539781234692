<template>
  <div :class="className">
    <nav
      aria-label="Breadcrumb"
      class="c-breadcrumbs"
      data-testid="breadcrumbs"
    >
      <ul class="!dnl-p-0 dnl-list-none dnl-flex dnl-gap-3 dnl-items-center">
        <li
          v-if="shouldShowHomeIcon"
          class="md:dnl-mr-6"
        >
          <router-link to="/">
            <svg class="dnl-w-5 dnl-h-5 dnl-text-blue-600">
              <use href="/img/icons/home.svg#home"></use>
            </svg>
          </router-link>
        </li>
        <template v-for="(breadcrumb, index) in breadcrumbs">
          <sl-tooltip
            :disabled="!breadcrumb.tooltipContent"
            :key="index"
            class="no-arrow breadcrumb-tooltip"
            placement="bottom-start"
          >
            <ul
              class="dnl-list-none dnl-pl-0!"
              slot="content"
            >
              <li
                v-for="(tooltipContent, tooltipIndex) in breadcrumb.tooltipContent"
                :key="tooltipIndex"
              >
                <span class="dnl-font-medium dnl-inline-block dnl-me-1">
                  {{ $tn(tooltipContent.title) }}
                </span>
                <span>{{ $tn(tooltipContent.text) }}</span>
              </li>
            </ul>

            <li>
              <router-link
                v-if="breadcrumb.to"
                data-testid="breadcrumb-item"
                class="dnl-no-underline dnl-text-md dnl-font-medium"
                :class="
                  index === breadcrumbs.length - 1 ? '!dnl-text-neutral-600' : '!dnl-text-blue-600'
                "
                :to="breadcrumb?.to"
                :disabled="breadcrumb?.disabled"
              >
                {{ $tn(breadcrumb.text) }}
              </router-link>

              <span
                v-else
                data-testid="breadcrumb-item"
                class="dnl-no-underline dnl-text-md dnl-font-medium dnl-text-neutral-600"
              >
                {{ $tn(breadcrumb.text) }}
              </span>
            </li>
          </sl-tooltip>
          <svg
            v-if="index !== breadcrumbs.length - 1"
            :key="'seperator' + index"
            viewBox="0 0 24 24"
            class="dnl-w-5 dnl-h-5 dnl-text-jeans-indigo"
          >
            <use href="/img/icons/chevron_right.svg#chevron_right"></use>
          </svg>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { Breadcrumbs } from '@/interfaces/BreadCrumbs';

  const props = defineProps({
    breadcrumbs: {
      type: Array<Breadcrumbs>,
      required: true,
    },
    hasHomeIcon: { type: Boolean, default: true },
    className: {
      type: String,
      default: '',
    },
  });

  const shouldShowHomeIcon = computed(() => {
    return props.breadcrumbs.length >= 2 && props.hasHomeIcon;
  });
</script>

<style scoped>
  sl-tooltip.breadcrumb-tooltip::part(base) {
    --sl-tooltip-background-color: var(--slate-100) !important;
  }
  sl-tooltip.breadcrumb-tooltip::part(body) {
    padding: 0.5rem;

    box-shadow:
      0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
</style>
