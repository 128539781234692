import router from '@/router/router';
import useFetchNotesAuditor from '@/api/notesAuditorInstance';
import { setLanguage } from '@/services/language.service';
import { socketDisconnect } from '@/socket';
import state from './auth-state';
import type { LoginAPIRes, LoginAPIResData, User, LoginFormData } from './auth-types';
import { stonlyTrack } from '@/utils/stonly';
import type { Locale } from '@/constants/languages';
import { parseJwt } from '@/utils/jwt';
import { env } from '@/constants/env';
import { initAutoLogout, removeAutoLogout } from '@/utils/auth/autoLogout';

export const timeBeforeRefreshToken = 300; // 5 minutes

export async function login(loginFormData: LoginFormData) {
  const { username, password } = loginFormData;

  const { data: response } = await useFetchNotesAuditor('/users/login')
    .post({
      username,
      password,
    })
    .json();

  const { data, success, errorData }: LoginAPIRes = response.value;

  if (success && data) {
    const { token, user } = data as { token: string; user: User; settings: any };

    setRefreshTokenTimeout(token);

    stonlyTrack('identify', user.id, {
      'browser-size': window.innerWidth,
      'group-id': user.groupId,
      'user-created-at': user.createdAt,
    });

    setLanguage(user.locale as Locale);
    storeAuthData({ token, user });

    const parsedToken = token ? parseJwt(token) : null;
    if (parsedToken && !parsedToken.session_refresh_when_idling) {
      initAutoLogout();
    }
  } else if (errorData) {
    state.authErrorData = errorData;
  }
  return response.value;
}

export async function loginAzure() {
  const querystring = `origin=${encodeURIComponent(window.location.origin)}`;
  window.location.replace(`${env.VITE_APP_URL_API}/azure/login-uri?${querystring}`, '_self');
}

export async function loginMazars() {
  const querystring = `origin=${encodeURIComponent(window.location.origin)}`;
  window.location.replace(`${env.VITE_APP_URL_API}/mazars/login-uri?${querystring}`, '_self');
}

export async function refreshUserCredentials(options?: {
  forceRefresh?: boolean;
  initLogoutTimer?: boolean;
}) {
  // Check if the token is still valid and has more than timeBeforeRefreshToken then no need to refresh
  // If the token is expired then logout the user
  if (state.token && !options?.forceRefresh) {
    const { exp, session_refresh_when_idling } = parseJwt(state.token);

    if (!session_refresh_when_idling && options?.initLogoutTimer) {
      initAutoLogout();
    }

    if (session_refresh_when_idling && exp > Date.now() / 1000 - timeBeforeRefreshToken) {
      setRefreshTokenTimeout(state.token);
      return { success: true };
    } else if (exp <= Date.now() / 1000) {
      clearAuthData();
      return { success: false };
    }
  }

  const { data: response } = await useFetchNotesAuditor('/users/refresh').get().json();
  const { data, success }: LoginAPIRes = response.value ?? {};

  if (success) {
    const { token, user } = data as LoginAPIResData;

    setRefreshTokenTimeout(token);

    stonlyTrack('identify', user.id, {
      'browser-size': window.innerWidth,
      'group-id': user.groupId,
      'user-created-at': user.createdAt,
    });

    storeAuthData({ token, user });
  } else {
    logout();
    state.userInfoErrorMessage = 'assignments.form.error.baseForm';
  }

  return response.value;
}

export function storeAuthData({ token, user }: LoginAPIResData) {
  state.token = token;
  state.user = user;
}

export function clearAuthData() {
  state.user = null;
  state.token = null;
}

export async function logout() {
  await router.push({ name: 'login', query: { logout: true } as any });
  clearAuthData();
  socketDisconnect();
  removeAutoLogout();
}

export function setRefreshTokenTimeout(token: string) {
  const parsedToken = parseJwt(token);
  if (!parsedToken.session_refresh_when_idling) return;

  clearTimeout(state.refreshTimeoutId);
  // Expire time minus the time before refresh token
  const logoutTimeout = (parseJwt(token).exp - timeBeforeRefreshToken) * 1000;
  const timeout = logoutTimeout - Date.now();

  state.refreshTimeoutId = window.setTimeout(refreshUserCredentials, timeout);
}
