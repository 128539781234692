export function toSorted(items: any[], compareFn?: (a: any, b: any) => number) {
  const copy = [...items];
  copy.sort(compareFn);

  return copy;
}

if (!Array.prototype.toSorted) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.toSorted = function (compareFn?: (a: any, b: any) => number) {
    return toSorted(this, compareFn);
  };
}
