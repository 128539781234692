import { throttle } from 'lodash';
import { globalError } from '@/store/modules/global/global-state';
import { logout, refreshUserCredentials } from '@/store/modules/auth/auth-actions';
import { parseJwt } from '../jwt';
import state from '@/store/modules/auth/auth-state';

export const throttlePeriod = 2 * 60;
const events: Array<keyof DocumentEventMap> = ['click', 'mousemove', 'wheel', 'keydown'];
export const tokenMinDelta = 60;

let timer: number | undefined = undefined;

async function logoutTimer(sessionLifetime: number, withRefreshCredentials = true) {
  // reset previous timer
  clearTimeout(timer);

  if (withRefreshCredentials) {
    await refreshUserCredentials({ forceRefresh: true });
  }

  timer = setTimeout(timerCallback, sessionLifetime * 1000);
}

/** Check if token still has exp date in the future.
 * Validation is needed in case timer is not fired
 * (for instance, when PC is hibernating) */
function validateTokenAndStartTimer(withRefreshCredentials: boolean) {
  const { token } = state;
  if (!token) return;

  const { session_lifetime } = parseJwt(token);

  if (getRemainingLifetime(token) <= tokenMinDelta) {
    autoLogout();
    return;
  }

  return logoutTimer(session_lifetime, withRefreshCredentials);
}

function getRemainingLifetime(token: string) {
  const { exp } = parseJwt(token);

  return exp - Date.now() / 1000;
}

function timerCallback() {
  const { token } = state;
  if (!token) return;

  const remainingLifetime = getRemainingLifetime(token);
  if (remainingLifetime > tokenMinDelta) {
    logoutTimer(remainingLifetime, true);
    return;
  }

  autoLogout();
}

function autoLogout() {
  logout();
  globalError.value = {
    text: 'errors.auth.autoSessionInvalidation',
  };
}

/** We want to throttle timer initialization, so we won't overwhelm BE with refresh token requests */
export function logoutTimerThrottled(throttleTimeout: number) {
  return throttle(validateTokenAndStartTimer, throttleTimeout * 1000, {
    leading: true,
    trailing: false,
  });
}

const initTimer = logoutTimerThrottled(throttlePeriod) as (withRefreshCredentials: any) => void;

function handleVisibilityChangeCallback() {
  if (!document.hidden) {
    initTimer(true);
  }
}

function interactionListener() {
  events.forEach(eventName => {
    document.addEventListener(eventName, initTimer);
  });

  document.addEventListener('visibilitychange', handleVisibilityChangeCallback);
}

function removeInteractionListener() {
  events.forEach(eventName => {
    document.removeEventListener(eventName, initTimer);
  });

  document.removeEventListener('visibilitychange', handleVisibilityChangeCallback);

  if (timer) {
    clearTimeout(timer);
  }
}

/** Initializes timers and event listeners for autologout */
export function initAutoLogout() {
  initTimer(false); // after registering we want immediately to start timer
  interactionListener();
}

export function removeAutoLogout() {
  removeInteractionListener();
}
